import { LinkProps } from 'next/link';
import React, { ComponentProps, FC } from 'react';
import { PlcApiResponseInterface } from '@api/business/api.hook';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { Text } from '@components/Text/Text.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { Spacing } from '@styles/Spacing.style';
import classnames from 'classnames';
import styles from './MostViewedContent.module.scss';

const MostViewedContentLoader: FC<{ uniqueKey: string }> = ({ uniqueKey }) => (
  <PlcTextListLoader
    textProps={[{ bottomSpacing: Spacing.basis, numberOfLine: 3, variant: 'h4', width: 300 }]}
    uniqueKey={uniqueKey}
    width={300}
  />
);

export type MostViewedContent = LinkProps & {
  title: string;
};

type Props = ComponentProps<'div'> & {
  variant?: 'normal' | 'compact';
  i18nTitle?: string;
  forceColumn?: boolean;
} & PlcApiResponseInterface<MostViewedContent[]>;

export const MostViewedContent: FC<Props> = ({
  variant = 'normal',
  i18nTitle = 'common.most-viewed-articles',
  forceColumn,
  className,
  data,
  isValidating,
  error,
  retryCallback,
  ...rest
}) => {
  const rootClass = classnames(styles['most-viewed-content'], variant, className);
  const marginBottom = classnames('plc-mb-m', variant !== styles.compact && 'plc-mb-up-m-s');
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const compactTitleVariant = isMobile ? 'h5' : 'h4';
  const titleVariant = variant !== 'compact' ? 'new-bold-p' : compactTitleVariant;

  if (error && !isValidating) {
    return null;
  }

  const olClassName = classnames('flex', 'column', 'wrap', !forceColumn && 'row-up-m', 'space-between', 'plc-pb-m');
  return (
    <section className={isMobile || variant === 'compact' ? '' : styles.wrapper}>
      <div className={rootClass} {...rest}>
        <Text
          className={classnames(marginBottom, variant === 'compact' ? '' : 'plc-pt-m')}
          i18nKey={i18nTitle}
          tag="p"
          variant="p-h3-upper-style"
        />
        <ol className={olClassName}>
          {isValidating ? (
            <>
              <MostViewedContentLoader uniqueKey="MostViewedContent1" />
              <MostViewedContentLoader uniqueKey="MostViewedContent2" />
              <MostViewedContentLoader uniqueKey="MostViewedContent3" />
              {!isMobile && variant === 'normal' && <MostViewedContentLoader uniqueKey="MostViewedContentMobile" />}
            </>
          ) : (
            (data || []).map(({ title, ...linkProps }, index) => (
              <li key={linkProps.as?.toString() || index} className={`flex ${marginBottom}`}>
                <Text tag="div" variant="h3">
                  {index + 1}.
                </Text>
                <PlcAnchor className="flex align-center" linkProps={linkProps}>
                  <Text tag="div" variant={titleVariant}>
                    {title}
                  </Text>
                </PlcAnchor>
              </li>
            ))
          )}
        </ol>
      </div>
    </section>
  );
};
