/* eslint-disable no-irregular-whitespace */
import { Content } from 'pleinchamp-api-client';
import React, { ComponentProps, FC } from 'react';
import { ChevronRightIcon } from '@components/icons/ChevronRight.icon';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { Text } from '@components/Text/Text.component';
import { EventDate } from '@events/components/EventDate/EventDate.component';
import { EventLocation } from '@events/components/EventLocation/EventLocation.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { PlcColor } from '@styles/colors';
import { Spacing } from '@styles/Spacing.style';
import { getAs, urlEnum } from '@utils/url';
import classnames from 'classnames';

interface Props extends ComponentProps<'li'> {
  event: Content;
  index?: number;
}

function JournalAgendaLine({ event, className, index = 0, ...rest }: Props) {
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  if (!event.eventDetail) {
    return null;
  }
  const { startDate, endDate } = event.eventDetail;
  const href = `${urlEnum.event}/[slug]`;
  const as = getAs(href, { slug: event.slug }, false);
  const rootClass = classnames('plc-mb-m', className);
  const childClass = classnames(
    index % 2 !== 0 && !isMobile ? 'plc-pl-m' : '',
    'flex',
    'column',
    'align-start',
    'plc-mb-m',
    'flexCenter'
  );

  return (
    <li className={rootClass} {...rest}>
      <div
        className={childClass}
        style={{ borderLeft: index % 2 !== 0 && !isMobile ? `2px solid ${PlcColor.GREY_DARK}` : '' }}
      >
        <EventDate
          className="white-space-nowrap-up-m plc-mb-s plc-mr-up-m-s"
          endDate={new Date(endDate)}
          longDateFormat
          startDate={new Date(startDate)}
          textProps={{ variant: 'date' }}
        />
        <PlcAnchor className="flex flexCenter row" linkProps={{ as, href }}>
          <Text className="cursor-pointer plc-mb-s plc-mr-up-m-xs" tag="p" variant="new-h3">
            {event.title}
          </Text>
          <div className="plc-px-xs">
            <ChevronRightIcon fillColor={PlcColor.GREEN} height={14} stroke={PlcColor.GREEN} width={14} />
          </div>
        </PlcAnchor>
        <EventLocation address={event.eventDetail.address} />
      </div>
    </li>
  );
}

const JournalAgendaLineLoader: FC<{ uniqueKey: string }> = ({ uniqueKey }) => {
  return (
    <li>
      <PlcTextListLoader
        textProps={[
          { bottomSpacing: Spacing.m, variant: 'h3', width: 100 },
          { bottomSpacing: Spacing.basis, numberOfLine: 2, variant: 'p', width: 200 },
        ]}
        uniqueKey={uniqueKey}
        width={200}
      />
    </li>
  );
};

export { JournalAgendaLine, JournalAgendaLineLoader };
