/* eslint-disable no-nested-ternary */
import { ThematicCode } from 'pleinchamp-api-client';
import { Content } from 'pleinchamp-api-client/dist/src/content/search';
import React, { ComponentProps, useCallback, useContext, useMemo } from 'react';
import { ADVERT_FORMAT_ID } from '@adverts/business/Adverts.contants';
import { insertAdvertInArray } from '@adverts/business/adverts.utils';
import { PlcApiResponseInterface } from '@api/business/api.hook';
import { PrimaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { PlcMessageWithRetry } from '@components/PlcMessage/PlcMessageWithRetry.component';
import { Text } from '@components/Text/Text.component';
import { ContentCardLoaderHomeVersion } from '@content/ContentCardHomeVersion/ContentCardLoaderHomeVersion.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { SearchContentType } from '@search/business/search.types';
import { Breakpoint, useIsBreakpointDown, useWindowInnerWidth } from '@styles/breakpoints';
import { NB_HOME_NEWS_HEADLINES } from '@utils/api.utils';
import { buildContentCard } from '@utils/card';
import { plcDayjs } from '@utils/date';
import { getAllContentTypeLinkProps } from '@utils/url';
import classnames from 'classnames';
import { GeneralContext } from 'features/business/app.contexts';
import styles from './JournalNewsHeadlines.module.scss';

type Props = ComponentProps<'section'> &
  PlcApiResponseInterface<Content[]> & {
    thematicPage?: ThematicCode;
    loading?: boolean;
  };

function sortNewestFirst(a: Content, b: Content) {
  const dateA = plcDayjs(a.publishDate);
  const dateB = plcDayjs(b.publishDate);

  if (dateA.isBefore(dateB)) {
    return 1;
  }
  if (dateA.isAfter(dateB)) {
    return -1;
  }
  return 0;
}

export const JournalNewsHeadlines = ({
  className,
  data,
  thematicPage,
  isValidating,
  loading,
  error,
  retryCallback,
  ...rest
}: Props) => {
  const { setSearchQuery } = useContext(GeneralContext);
  const resetSearchQuery = useCallback(() => setSearchQuery?.(''), [setSearchQuery]);
  const isMobile = useIsBreakpointDown(Breakpoint.s);

  const nombreLigneArticle = (): number => {
    if (thematicPage && isMobile) {
      return NB_HOME_NEWS_HEADLINES - 1;
    }
    if (thematicPage || isMobile) {
      return NB_HOME_NEWS_HEADLINES;
    }
    return NB_HOME_NEWS_HEADLINES + 1;
  };

  const newsWithAd = useMemo(
    () =>
      insertAdvertInArray(
        (data || [])
          .sort(sortNewestFirst)
          .map((item, index) => buildContentCard(item, isMobile, 'home-version', index, data)),
        isMobile && !thematicPage ? 2 : 3,
        nombreLigneArticle(),
        ADVERT_FORMAT_ID.NativeAd
      ),
    [data, isMobile]
  );

  const rootClass = classnames(styles['journal-news-headlines'], className);
  const windowWidth = useWindowInnerWidth();
  const allHref = getAllContentTypeLinkProps(
    SearchContentType.Article,
    undefined,
    undefined,
    thematicPage ? [thematicPage] : undefined
  );

  const renderContent = () => {
    if (loading || isValidating) {
      return (
        <>
          <div className={styles.newsContainer}>
            <ContentCardLoaderHomeVersion uniqueKey="1" />
            <ContentCardLoaderHomeVersion uniqueKey="2" />
            <ContentCardLoaderHomeVersion uniqueKey="3" />
          </div>
        </>
      );
    }

    if (error) {
      return <PlcMessageWithRetry retryCallback={retryCallback} />;
    }

    return (
      <>
        <div>
          {thematicPage && data?.length === 0 ? (
            <Text i18nKey="thematic.article.no-result" variant="new-bold-p" />
          ) : (
            newsWithAd
          )}
        </div>
        {(thematicPage || !isMobile) && (
          <div className="flexCenter plc-mt-xs">
            <PlcAnchor className="see-all flex block-up-s" linkProps={{ href: allHref }}>
              <PrimaryButton
                className={classnames(
                  'flex flex-1 block-up-s',
                  thematicPage && windowWidth && windowWidth < 409 ? styles.noSpace : ''
                )}
                isLightGreen
                onClick={resetSearchQuery}
                textProps={{
                  alignment: 'center',
                  i18nKey: thematicPage ? 'thematic.article.see-all' : 'actu.last.see-all',
                }}
                wrapperClassName="flex-1"
              />
            </PlcAnchor>
          </div>
        )}
      </>
    );
  };

  return (
    <section className={styles.newsWrapper} {...rest}>
      <div className={rootClass}>
        {thematicPage ? (
          <Text
            className="plc-mb-s"
            i18nKey={`thematic.${thematicPage}.article.title`}
            tag="p"
            variant="p-h3-upper-style"
          />
        ) : isMobile ? (
          <div className="flex plc-mb-s align-center">
            <Text i18nKey="home.headlines.title" tag="p" variant="p-h3-upper-style" />
            <PlcAnchor
              className={`${window.innerWidth < 350 ? 'plc-neg-mr-m' : ''} flex block-up-s plc-ml-auto`}
              linkProps={{ href: allHref }}
            >
              <PrimaryButton
                className="flex flex-1 block-up-s"
                isLightGreen
                onClick={resetSearchQuery}
                textProps={{ i18nKey: 'actu.last.see-all' }}
                wrapperClassName="flex-1"
              />
            </PlcAnchor>
          </div>
        ) : (
          <Text className="plc-mb-s" i18nKey="home.headlines.title" tag="p" variant="p-h4-upper-style" />
        )}
        {renderContent()}
      </div>
    </section>
  );
};
