import { Trans } from 'next-i18next';
import React, { ComponentPropsWithRef, FC } from 'react';
import { Text } from '@components/Text/Text.component';
import { TextPropsOptionalVariant } from '@components/Text/Text.types';
import { getEventFormatedDate } from '@events/business/events.utils';
import { formatDateWithDay, formatDateWithoutYear, formatFullDate, plcDayjs } from '@utils/date';
import classnames from 'classnames';

type Props = {
  startDate: Date;
  endDate?: Date;
  compact?: boolean;
  textProps?: TextPropsOptionalVariant;
  longDateFormat?: boolean;
} & ComponentPropsWithRef<'div'>;

const EventDate: FC<Props> = ({
  startDate,
  endDate,
  compact = false,
  longDateFormat = false,
  className,
  textProps = {},
  ...rest
}) => {
  const start = plcDayjs(startDate);
  const end = endDate ? plcDayjs(endDate) : undefined;
  let innerHTML = <></>;

  if (compact) {
    // @ts-ignore
    innerHTML = getEventFormatedDate(start, end).reduce((total, text, index, sourceArray) => {
      if (index === 0) {
        return text;
      }
      if (index === sourceArray.length - 1) {
        return (
          <>
            {total}
            <br />
            {text}
          </>
        );
      }
      return `${total} ${text}`;
    }, null);
  } else {
    const from = formatDateWithDay(start);
    innerHTML = (
      <Trans i18nKey="common.date" tOptions={{ date: from }}>
        Le <time dateTime={start.toISOString()}>{from}</time>
      </Trans>
    );

    if (end && !start.isSame(end, 'day')) {
      const to = formatDateWithDay(end);
      innerHTML = (
        <Trans i18nKey="common.from-to" tOptions={{ from, to }}>
          Du <time dateTime={start.toISOString()}>{from}</time> au <time dateTime={end.toISOString()}>{to}</time>
        </Trans>
      );
    }
  }

  if (longDateFormat && !compact) {
    if (!end || (end && start.isSame(end, 'day'))) {
      const from = formatFullDate(start);
      innerHTML = (
        <Trans i18nKey="common.date" tOptions={{ date: from }}>
          Le {from}
        </Trans>
      );
    } else {
      let from: string;
      if (start.isSame(end, 'month')) {
        from = start.date().toLocaleString();
      } else if (start.isSame(end, 'year')) {
        from = formatDateWithoutYear(start);
      } else {
        from = formatFullDate(start);
      }
      const to = formatFullDate(end);
      innerHTML = (
        <Trans i18nKey="common.from-to" tOptions={{ from, to }}>
          Du {from} au {to}
        </Trans>
      );
    }
  }
  const rootClassname = classnames('event-date', className);

  const { tag = 'span', variant = 'h6', ...restTextProps } = textProps;
  return (
    <div className={rootClassname} {...rest}>
      <Text tag={tag} variant={variant} {...restTextProps}>
        {innerHTML}
      </Text>
    </div>
  );
};

export { EventDate };
