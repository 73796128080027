import React, { ComponentPropsWithRef, FC } from 'react';
import { PlcImgLoader } from '@components/Loaders/PlcImgLoader.component';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { Spacing } from '@styles/Spacing.style';
import classnames from 'classnames';
import styles from './ContentCardHomeVersion.module.scss';

type Props = ComponentPropsWithRef<'article'> & { uniqueKey?: string };

const ContentCardLoaderHomeVersion: FC<Props> = ({ className, uniqueKey = 'my-random-value', ...rest }) => {
  const rootClass = classnames(styles['content-card'], className, 'flex new-grid-m-2-s-2');
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const width = isMobile ? 200 : 325;

  return (
    <article className={rootClass} {...rest}>
      <div className={styles['card-title']}>
        <PlcTextListLoader
          textProps={[
            { bottomSpacing: Spacing.xs, numberOfLine: 1, variant: 'new-h3', width: 0.7 * width },
            { variant: 'p', width: 140 },
          ]}
          uniqueKey={`${uniqueKey}-txt`}
          width={width}
        />
      </div>
      <header>
        <div className={styles['card-image']}>
          <PlcImgLoader uniqueKey={`${uniqueKey}-img`} width={0.7 * width} />
        </div>
      </header>
    </article>
  );
};

export { ContentCardLoaderHomeVersion };
