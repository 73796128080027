/* eslint-disable react-hooks/rules-of-hooks */
import kebabCase from 'lodash/kebabCase';
import {
  Analysis,
  MarketForLastAnalysis,
  ProductCategoryCode,
  ProductTypeForLastAnalysis,
} from 'pleinchamp-api-client';
import React, { ComponentProps, FC, useContext } from 'react';
import { PlcApiResponseInterface } from '@api/business/api.hook';
import { PrimaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { Text } from '@components/Text/Text.component';
import { ChevronRightIcon } from '@icons/ChevronRight.icon';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { StocksHomeTabs } from '@stocks/business/Stocks.types';
import { getUrlStockTypeString, transformAnalyseTabToUrl } from '@stocks/business/Stocks.utils';
import { StocksAnalysisTab } from '@stocks/business/StocksAnalysisTab.types';
import { Breakpoint, useBreakpoint } from '@styles/breakpoints';
import { PlcColor } from '@styles/colors';
import { formatDateDayAndMonth } from '@utils/date';
import { getLinkProps, urlEnum } from '@utils/url';
import classnames from 'classnames';
import { GeneralContext } from 'features/business/app.contexts';
import styles from './LastAnalysisComponent.module.scss';

type Props = ComponentProps<'div'> & {
  variant?: 'normal' | 'compact';
  i18nTitleSuite?: string;
  isMobile?: boolean;
  isStocksHomePage?: boolean;
} & PlcApiResponseInterface<Analysis[]>;

function getLinkForLastAnalysis(
  productType: ProductTypeForLastAnalysis,
  analyseTab: StocksAnalysisTab,
  market?: MarketForLastAnalysis
) {
  const tabUrl = transformAnalyseTabToUrl(analyseTab);

  const productParams = [[productType.id.toString(), kebabCase(productType.name)].join('-'), tabUrl];

  const href = `${urlEnum.stocks}/[stockType]/[...productParams]`;
  if (market && productType.productCategoryCode === ProductCategoryCode.Cattle) {
    productParams.push([market.id.toString(), kebabCase(market.name)].join('-'));
  }
  const as = `${urlEnum.stocks}/${getUrlStockTypeString(productType.productCategoryCode)}/${productParams.join('/')}`;
  // /#tab-analysis`;

  return {
    as,
    href,
  };
}
function titleLastAnalysis(r: Analysis) {
  const titleFinal = `${r.productType?.name} : ${r.title ? r.title : ''}`;
  const { windowWidth } = useContext(GeneralContext);
  let valueTruncate: number;
  if (windowWidth === Breakpoint.m) {
    valueTruncate = 30;
  } else {
    switch (useBreakpoint()) {
      case Breakpoint.xs:
        valueTruncate = 20;
        break;
      case Breakpoint.s:
        valueTruncate = 26;
        break;
      case Breakpoint.m:
        valueTruncate = 38;
        break;
      case Breakpoint.l:
        valueTruncate = 30;
        break;
      default:
        valueTruncate = 43;
    }
  }
  return titleFinal.length > valueTruncate ? `${titleFinal.substring(0, valueTruncate - 1)}...` : titleFinal;
}

export const LastAnalysisComponent: FC<Props> = ({
  isMobile = false,
  isStocksHomePage = false,
  className,
  data,
  isValidating,
  error,
  retryCallback,
  ...rest
}) => {
  if (error && !isValidating) {
    return null;
  }

  return (
    <div className={classnames(className, styles.root)} {...rest}>
      {isMobile ? (
        <div className="flex plc-mb-s align-center">
          <Text className="plc-mt-xxs" i18nKey="common.last-analysis.home.mobile" tag="p" variant="p-h3-upper-style" />
          {!isStocksHomePage && (
            <PlcAnchor
              className={`${window.innerWidth < 350 ? 'plc-neg-mr-basis' : ''} flex block-up-s plc-ml-auto`}
              linkProps={getLinkProps(`${urlEnum.stocks}/[stockType]`, { stockType: StocksHomeTabs.Crops })}
            >
              <PrimaryButton
                className="flex flex-1 block-up-s"
                isLightGreen
                textProps={{
                  i18nKey: 'common.last-analysis.button.all',
                  variant: 'new-bold-p',
                }}
                wrapperClassName="flex-1"
              />
            </PlcAnchor>
          )}
        </div>
      ) : (
        <Text className="plc-mt-xxs plc-mb-s" i18nKey="common.last-analysis" tag="p" variant="p-h3-upper-style" />
      )}
      <ol className={classnames(styles['ol-analyse'], 'flex', 'column', 'wrap', 'row-up-m')}>
        {data &&
          data.length > 0 &&
          data.map((r, index) => (
            <li
              key={r.id}
              style={{ borderLeft: index % 2 !== 0 && !isMobile ? `2px solid ${PlcColor.GREY_DARK}` : '' }}
            >
              <PlcAnchor
                className="flex align-center"
                linkProps={getLinkForLastAnalysis(
                  r.productType as ProductTypeForLastAnalysis,
                  StocksAnalysisTab.Analyse,
                  r.productType?.marketReference
                )}
              >
                <Text
                  className={`${index % 2 !== 0 && !isMobile ? 'plc-ml-up-l-m plc-ml-up-m-s' : ''} cursor-pointer plc-mb-xxs plc-mr-xxs`}
                  tag="p"
                  variant="date"
                >
                  {`${formatDateDayAndMonth(r.date)} -`}
                </Text>
                <Text className="cursor-pointer plc-mb-xxs plc-mr-m-m" tag="p" variant="new-bold-p">
                  {titleLastAnalysis(r)}
                </Text>
                <ChevronRightIcon
                  className={`${index % 2 === 0 ? 'plc-mr-up-l-m plc-mr-up-m-s' : ''} plc-ml-auto`}
                  fillColor={PlcColor.GREEN}
                  height={12}
                  stroke={PlcColor.GREEN}
                  width={12}
                />
              </PlcAnchor>
            </li>
          ))}
      </ol>
    </div>
  );
};
