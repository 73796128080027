import { CustomJournal } from '../CustomJournal/CustomJournal';
import { JournalAgenda } from '../JournalAgenda/JournalAgenda.component';
import { JournalFreeField } from '../JournalFreeField/JournalFreeField.component';
import { JournalNewsHeadlines } from '../JournalNewsHeadlines/JournalNewsHeadlines.component';
import { HomeContext } from 'pages/index';
import React, { ComponentPropsWithRef, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ADVERT_FORMAT_ID, ADVERT_PAGE_ID } from '@adverts/business/Adverts.contants';
import { useAdvert } from '@adverts/business/useAdvert';
import { AdProps } from '@adverts/components/GenericAd.component';
import { usePlcApiCall } from '@api/business/api.hook';
import { fetchMostViewedNews } from '@api/business/api.utils';
import { MostViewedContent } from '@components/content/MostViewedContent/MostViewedContent.component';
import { Text } from '@components/Text/Text.component';
import { ThematicContent } from '@components/ThematicContent/ThematicContent.component';
import { PlcPageLayout } from '@layout/components/PlcPageLayout/PlcPageLayout.component';
import { getLastCity } from '@meteo/business/meteo.utils';
import { MeteoHomeSummary } from '@meteo/components/MeteoHomeSummary/MeteoHomeSummary.component';
import LastAnalysis from '@stocks/components/LastAnalysis/LastAnalysis.component';
import { Breakpoint, useIsBreakpointDown, useIsBreakpointDownOrEqual } from '@styles/breakpoints';
import { userSelectors } from '@user/business/User.store';
import { NB_HOME_MOST_VIEWED_NEWS } from '@utils/api.utils';
import { fetchNewHeadlines } from '@utils/fetch';
import { AsUrlParams, getAs } from '@utils/url';
import { ATLevel2 } from 'features/tagPlan/business/TagPlan.types';
import dynamic from 'next/dynamic';

const TAG_PROPS = {
  level2: ATLevel2.Home,
  tagPageNameI18nArray: ['home.tag.title'],
};

const HomeBannerComponent = dynamic<ComponentPropsWithRef<'div'>>(() =>
  import('@components/HomeBanner/HomeBanner.component').then((module) => module.HomeBannerComponent)
);

const GenericAd = dynamic<AdProps>(
  () => import('@adverts/components/GenericAd.component').then((module) => module.GenericAd),
  {
    ssr: false,
  }
);

const JournalPro = dynamic<ComponentPropsWithRef<'div'>>(
  () => import('../JournalPro/JournalPro.component').then((module) => module.JournalPro),
  {
    ssr: false,
  }
);

export function getLinkProps(url: string, params: AsUrlParams, forceKebabCase = true) {
  return {
    as: getAs(url, params, forceKebabCase),
    href: url,
  };
}

function HomeLayout() {
  const {
    mostViewedNews: { initialData },
    newsHeadlines: { initialData: news },
  } = useContext(HomeContext);
  const mostViewedNews = usePlcApiCall(fetchMostViewedNews, [NB_HOME_MOST_VIEWED_NEWS], {
    initialData,
  });
  const { data: newsHeadlines, ...restNewsHeadlines } = usePlcApiCall(fetchNewHeadlines, [undefined], {
    initialData: news,
  });
  const isPro = useSelector(userSelectors.isPro);

  const [city, setCity] = useState(getLastCity());

  const isSmartphone = useIsBreakpointDown(Breakpoint.s);
  const isSmartphoneOrTablet = useIsBreakpointDownOrEqual(Breakpoint.m);

  useAdvert({
    pageId: ADVERT_PAGE_ID.Home,
  });

  return (
    <PlcPageLayout
      appNameAsPrefix
      className="journal plc-mb-0"
      metaDescI18n="home.meta-desc"
      metaTitleI18n="home.meta-title"
      tagProps={TAG_PROPS}
    >
      <GenericAd formatId={isSmartphoneOrTablet ? ADVERT_FORMAT_ID.MegaBanner : ADVERT_FORMAT_ID.Arch} />
      {/* For SEO purpose, have a hidden h1 title (PLC-2455) */}
      <Text i18nKey="common.app-name" style={{ position: 'absolute', top: -1000 }} variant="h1" />
      <MeteoHomeSummary reversedCity={city} setCity={setCity} />
      <JournalNewsHeadlines className="wrapper-s" data={newsHeadlines} {...restNewsHeadlines} />
      <ThematicContent className="plc-mb-m" />
      {isSmartphone && <HomeBannerComponent />}
      <LastAnalysis parentDivClass="wrapper-s plc-mb-basis" />
      {isSmartphone ? <GenericAd formatId={ADVERT_FORMAT_ID.WideAngle} /> : <HomeBannerComponent />}
      <CustomJournal className="background-ultralight plc-py-m plc-py-up-m-m" />
      <GenericAd className="wrapper-s plc-mb-basis InlineVideoIndex" formatId={ADVERT_FORMAT_ID.InlineVideo} />
      <JournalFreeField />
      <MostViewedContent className="wrapper-s plc-mb-up-m-m plc-mb-s" {...mostViewedNews} />
      {isSmartphone && <HomeBannerComponent />}
      <JournalAgenda className="wrapper-s" />
      {!isPro && <JournalPro />}
    </PlcPageLayout>
  );
}

export { HomeLayout };
