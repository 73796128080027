/* eslint-disable no-nested-ternary */
import isEmpty from 'lodash/isEmpty';
import { HomeContext } from 'pages/index';
import { Content } from 'pleinchamp-api-client/dist/src/content/search';
import React, { ComponentPropsWithRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { usePlcApiCall } from '@api/business/api.hook';
import { PrimaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { PlcMessageWithRetry } from '@components/PlcMessage/PlcMessageWithRetry.component';
import { Text } from '@components/Text/Text.component';
import { AgendaIcon } from '@icons/Agenda.icon';
import {
  JournalAgendaLine,
  JournalAgendaLineLoader,
} from '@journal/components/JournalAgenda/JournalAgendaLine/JournalAgendaLine.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { SearchContentType } from '@search/business/search.types';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { PlcColor } from '@styles/colors';
import { userSelectors } from '@user/business/User.store';
import { fetchEvents } from '@utils/fetch';
import { getAllContentTypeLinkProps } from '@utils/url';
import classnames from 'classnames';
import styles from './JournalAgenda.module.scss';

type Props = ComponentPropsWithRef<'section'>;

function JournalAgenda({ className, ...rest }: Props) {
  const isSmartphone = useIsBreakpointDown(Breakpoint.s);
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const {
    events: { initialData },
  } = useContext(HomeContext);

  const user = useSelector(userSelectors.getUser);
  const stateOrProvinceCode = user?.address?.stateOrProvinceCode;
  const {
    data: events,
    isValidating,
    error,
    retryCallback,
  } = usePlcApiCall(fetchEvents, [stateOrProvinceCode], {
    deduping: false,
    initialData,
  });

  const displayedEvents: Content[] = events ? events.slice(0, isSmartphone ? 2 : 4) : [];

  const rootClassnames = classnames(styles.agendaRoot, className);
  const ulClassName = classnames('flex', 'column', 'wrap', 'row-up-m', 'margin');
  return (
    <section className={rootClassnames} {...rest}>
      {isMobile ? (
        <div className="flex flexCenter plc-mb-m">
          <AgendaIcon />
          <PlcAnchor
            className="flex block-up-m plc-ml-auto"
            linkProps={{ href: getAllContentTypeLinkProps(SearchContentType.Event) }}
          >
            <PrimaryButton
              className="flex flex-1"
              isLightGreen
              isSmall={false}
              textProps={{ i18nKey: 'home.go-to.all-events' }}
              wrapperClassName="flex flex-1"
            />
          </PlcAnchor>
        </div>
      ) : (
        <Text className="plc-mb-m" i18nKey="search.filter.event" tag="h2" variant="p-h3-upper-style" />
      )}
      <ul className={ulClassName}>
        {isValidating ? (
          <>
            <JournalAgendaLineLoader uniqueKey="JournalAgendaLine1" />
            <JournalAgendaLineLoader uniqueKey="JournalAgendaLine2" />
            <JournalAgendaLineLoader uniqueKey="JournalAgendaLine3" />
            <JournalAgendaLineLoader uniqueKey="JournalAgendaLine4" />
          </>
        ) : error ? (
          <PlcMessageWithRetry retryCallback={retryCallback} />
        ) : (
          <>
            {displayedEvents.map((event: Content, index: number) => (
              <JournalAgendaLine
                key={event.id}
                event={event}
                index={index}
                style={{
                  borderBottom:
                    index < 2 && index < displayedEvents.length - 1 ? `1px solid ${PlcColor.LIGHT_GREEN}` : undefined,
                }}
              />
            ))}
            {isEmpty(displayedEvents) && (
              <Text className="plc-mb-basis" flavour="grey" i18nKey="home.agenda.no-event" variant="p" />
            )}
          </>
        )}
      </ul>
      {!isMobile && (
        <div className="flexCenter">
          <PlcAnchor
            className="flex plc-mb-l block-up-m"
            linkProps={{ href: getAllContentTypeLinkProps(SearchContentType.Event) }}
          >
            <PrimaryButton
              className="flex flex-1"
              isLightGreen
              isSmall={false}
              textProps={{ i18nKey: 'home.go-to.all-events' }}
              wrapperClassName="flex flex-1"
            />
          </PlcAnchor>
        </div>
      )}
    </section>
  );
}

export { JournalAgenda };
