import { LastAnalysisComponent } from './LastAnalysisComponent.component';
import { HomeContext } from 'pages/index';
import { useContext } from 'react';
import { usePlcApiCall } from '@api/business/api.hook';
import { fetchLastAnalysis } from '@api/business/api.utils';
import { PrimaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { StocksHomeTabs } from '@stocks/business/Stocks.types';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { getLinkProps, urlEnum } from '@utils/url';

type Props = { parentDivClass?: string; isStocksHomePage?: boolean };

function LastAnalysis({ parentDivClass, isStocksHomePage = false }: Props) {
  const {
    analyse: { initialData: initialAnalyse },
  } = useContext(HomeContext);

  const analyse = usePlcApiCall(fetchLastAnalysis, [], {
    initialData: initialAnalyse,
  });
  const isMobile = useIsBreakpointDown(Breakpoint.m);

  return (
    <div className={parentDivClass}>
      <LastAnalysisComponent
        className="plc-mb-basis"
        data={analyse.data}
        isMobile={isMobile}
        isStocksHomePage={isStocksHomePage}
        isValidating={analyse.isValidating}
      />
      {!isMobile && !isStocksHomePage && (
        <div className="flexCenter">
          <PlcAnchor
            className="flex block-up-s"
            linkProps={getLinkProps(`${urlEnum.stocks}/[stockType]`, { stockType: StocksHomeTabs.Crops })}
          >
            <PrimaryButton
              className="flex flex-1 block-up-s"
              isLightGreen
              textProps={{ i18nKey: 'common.last-analysis.button.all' }}
              wrapperClassName="flex-1"
            />
          </PlcAnchor>
        </div>
      )}
    </div>
  );
}

export default LastAnalysis;
