import React from 'react';
import { SquareSvgProps } from '@icons/icon.types';
import { PlcColor } from '@styles/colors';

function AgendaIcon({ title, size = 40, fillColor = PlcColor.BLACK, ...props }: SquareSvgProps) {
  return (
    <svg height={size} viewBox="0 0 40 40" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      {title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M30.0001 3.33325C30.8411 3.33299 31.651 3.6506 32.2677 4.22241C32.8843 4.79423 33.262 5.57799 33.3251 6.41659L33.3334 6.66659V33.3333C33.3337 34.1742 33.0161 34.9842 32.4443 35.6008C31.8725 36.2175 31.0887 36.5952 30.2501 36.6583L30.0001 36.6666H10.0001C9.15915 36.6669 8.34916 36.3492 7.73253 35.7774C7.11589 35.2056 6.73818 34.4218 6.67511 33.5833L6.66677 33.3333V31.6666C6.24197 31.6661 5.83339 31.5035 5.52449 31.2118C5.2156 30.9202 5.02972 30.5217 5.00482 30.0976C4.97992 29.6735 5.1179 29.256 5.39055 28.9302C5.6632 28.6044 6.04995 28.3951 6.47177 28.3449L6.66677 28.3333V24.9999C6.24197 24.9994 5.83339 24.8368 5.52449 24.5452C5.2156 24.2536 5.02972 23.855 5.00482 23.4309C4.97992 23.0069 5.1179 22.5893 5.39055 22.2635C5.6632 21.9378 6.04995 21.7284 6.47177 21.6783L6.66677 21.6666V18.3333C6.24197 18.3328 5.83339 18.1701 5.52449 17.8785C5.2156 17.5869 5.02972 17.1883 5.00482 16.7643C4.97992 16.3402 5.1179 15.9226 5.39055 15.5969C5.6632 15.2711 6.04995 15.0618 6.47177 15.0116L6.66677 14.9999V11.6666C6.24197 11.6661 5.83339 11.5035 5.52449 11.2118C5.2156 10.9202 5.02972 10.5217 5.00482 10.0976C4.97992 9.67352 5.1179 9.25595 5.39055 8.9302C5.6632 8.60444 6.04995 8.39509 6.47177 8.34492L6.66677 8.33325V6.66659C6.66651 5.82562 6.98412 5.01564 7.55593 4.39901C8.12775 3.78237 8.91151 3.40466 9.75011 3.34159L10.0001 3.33325H30.0001ZM30.0001 6.66659H10.0001V33.3333H30.0001V6.66659ZM25.8334 9.99992C26.4549 9.99995 27.0541 10.2315 27.5141 10.6493C27.9742 11.0671 28.2621 11.6413 28.3218 12.2599L28.3334 12.4999V15.8333C28.3334 16.4547 28.1019 17.0539 27.6841 17.514C27.2662 17.974 26.692 18.2619 26.0734 18.3216L25.8334 18.3333H14.1668C13.5453 18.3332 12.9461 18.1017 12.4861 17.6839C12.026 17.2661 11.7381 16.6919 11.6784 16.0733L11.6668 15.8333V12.4999C11.6668 11.8784 11.8983 11.2793 12.3161 10.8192C12.734 10.3592 13.3082 10.0712 13.9268 10.0116L14.1668 9.99992H25.8334ZM25.0001 13.3333H15.0001V14.9999H25.0001V13.3333Z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export { AgendaIcon };
